#root {
    overflow: hidden;
    overflow-y: scroll;
}

@font-face {
    font-family: 'Public Sans';
    src:    url('../fonts/PublicSans/PublicSans-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Public Sans';
    src:    url('../fonts/PublicSans/PublicSans-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Public Sans';
    src:    url('../fonts/PublicSans/PublicSans-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Public Sans';
    src:    url('../fonts/PublicSans/PublicSans-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Public Sans';
    src:    url('../fonts/PublicSans/PublicSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Public Sans';
    src:    url('../fonts/PublicSans/PublicSans-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Public Sans';
    src:    url('../fonts/PublicSans/PublicSans-Black.ttf') format('truetype');
    font-weight: 900;
}

@font-face {
    font-family: 'Barlow';
    src:    url('../fonts/Barlow/Barlow-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Barlow';
    src:    url('../fonts/Barlow/Barlow-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Barlow';
    src:    url('../fonts/Barlow/Barlow-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Barlow';
    src:    url('../fonts/Barlow/Barlow-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Barlow';
    src:    url('../fonts/Barlow/Barlow-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Barlow';
    src:    url('../fonts/Barlow/Barlow-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Barlow';
    src:    url('../fonts/Barlow/Barlow-Black.ttf') format('truetype');
    font-weight: 900;
}

a {
    color: inherit;
    transition: 0.2s;
}

* { 
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: 'Public Sans';
    touch-action: pan-y;
}

body {
    font-family: 'Public Sans';
    touch-action: pan-y;
}

.slick-slider, .slick-list, .slick-track, .slick-slide {
    touch-action: pan-y;
}

.plyr {
    clip-path: polygon(16% 0, 100% 0, 100% 100%, 0 100%, 0 16%, 16% 16%);
    @media (max-width: 768px) {
        height: 53vw;
        min-height: 53vw;
        min-width: 70%;
        width: 70%;
    }
}